import React from "react"
import AssetPageTableTitle from "./title"
import ctl from "@netlify/classnames-template-literals"
import AssetTableRow from "./row"

const AssetPageTable = ({ title, comingSoon, data = [] }) => {
  return (
    <div className={tablePageStyle}>
      <AssetPageTableTitle title={title} comingSoon={comingSoon}/>

      <div className={tableWrapperStyle}>
        <table className={tableStyle}>
          <tbody>
            {data.map((row, index) => {
              return <AssetTableRow key={`row-${index}`} row={row} index={index} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const tablePageStyle = ctl(
  `md:mt-0 mt-[40px] md:first:mb-[81px] max-w-[250px] md:mx-0 mx-auto `
)
const tableWrapperStyle = ctl(`border border-blue rounded-[10px]`)
const tableStyle = ctl(
  `border-collapse rounded-[10px] border-blue border overflow-hidden w-full`
)

export default AssetPageTable
