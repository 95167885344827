import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { SingleTicker } from "react-tradingview-embed"
import ButtonGroup from "../../../utils/button/group"

const AssetsInfo = ({ stockId, description }) => {
  return (
    <div className={infoWrapperStyle}>
      <SingleTicker
        widgetProps={{
          colorTheme: "light",
          symbol: stockId,
          autoSize: true
        }}
      />
      <div className='mt-10'>
        {
          description.map((description) => (
            <p className={`text-base ${description.className || 'mt-[28px]'}`}>
              {renderDescription(description)}
            </p>
          ))
        }
      </div>
      <ButtonGroup className='md:mt-10 md-max:gap-y-[20px]' fullWidthOnMobile buttons={buttons} />
    </div>
  )
}

const renderDescription = (description) => {
  return [].concat(description).map(desc => {
    if(desc.custom){
      return desc.text
    }

    if(desc.text){
      return (
        <a
          href={desc.link}
          target='_blank'
          className={`text-button-blue underline`}
        >
          {desc.text}
        </a>
      )
    }

    return desc
  })
}

const infoWrapperStyle = ctl(
  `flex flex-col md:mb-[60px] mb-[40px] md:text-left text-center`
)

const buttons = [
  {
    label: "Open An Account",
    url: "https://clients.eagleglobalmarkets.com/#/step-one",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
  {
    label: "Try Demo Trading",
    url: "https://mt4.egmarkets.trade/",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
]

export default AssetsInfo
