import React from "react"
import ctl from "@netlify/classnames-template-literals"

const AssetTableRow = ({ row }) => {
  return (
    <tr>
      {row.map((data, index) => {
        return (
          <td
            key={index}
            className={tableDataStyle}
            dangerouslySetInnerHTML={{ __html: data }}
          ></td>
        )
      })}
    </tr>
  )
}

const tableDataStyle = ctl(`border-blue border p-3 w-[123px]`)

export default AssetTableRow
