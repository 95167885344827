import React from "react"
import ctl from "@netlify/classnames-template-literals"

const AssetsPageDisclaimer = () => {
  return (
    <section className={disclaimerStyle}>
      <p className='mt-0'>
        ** The spreads provided are a reflection of the time-weighted average.
        Though we attempt to provide competitive spreads during all trading
        hours, clients should note that these may vary and are susceptible to
        underlying market conditions. The above is provided for indicative
        purposes only. Clients are advised to check important news announcements
        on our Economic Calendar, which may result in the widening of spreads,
        amongst other instances.
      </p>

      <p>
        The above spreads are applicable under normal trading conditions.
        Egmarkets has the right to amend the above spreads according to market
        conditions as per the ‘Terms and Conditions’.
      </p>
    </section>
  )
}

const disclaimerStyle = ctl(`md:text-left mt-[100px] text-center italic text-[15px]`)

export default AssetsPageDisclaimer
