import React from "react"

import Layout from "../../../layout"
import Container from "../../../utils/container"
import AssetsPageDisclaimer from "./disclaimer"

import ctl from "@netlify/classnames-template-literals"
import AssetsInfo from "./info"
import AssetPageTable from "../table"

const AssetsSinglePage = ({ pageContext }) => {
  const { name, cloudtrade, mt4, stockId, description, meta } = pageContext || {}

  return (
    <Layout title={name} description={meta}>
      <Container size="medium" className={containerStyle}>
        <div className={contentWrapper}>
          <AssetsInfo description={description} stockId={stockId}/>
        </div>
        <div>
          <AssetPageTable title="MT4 Markets" data={mt4} />
          <AssetPageTable comingSoon title="Eagletrade Markets" data={cloudtrade} />
        </div>
      </Container>
      <Container size='medium'>
        <AssetsPageDisclaimer />
      </Container>
    </Layout>
  )
}

const containerStyle = ctl(
  `flex md:flex-row flex-col justify-between md:pt-[90px] pt-[60px] md-max:px-[30px]`
)

const contentWrapper = ctl(`max-w-[557px] md-max:mb-6`)

export default AssetsSinglePage
